const Select = ({ onChange, data, className, value, size }) => {
  return (
    <div className={`relative ${className}`}>
      <select
        className={`block appearance-none w-full bg-white border border-gray-300 text-gray-700 ${
          size === "sm" ? "py-0 h-[30px]" : "py-1.5"
        } px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-primary sm:text-sm`}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={value}
      >
        {data.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export default Select;
