export const checkTypeDocDemo = (typeDoc) => {
  switch (typeDoc) {
    case "Courrier":
      return "Modèle Courrier FTC.pdf";
    case "Évaluation":
      return "Modèle Évaluation FTC.pdf";
    case "Téléchargement Dispositif":
      return "Modèle téléchargement dispositif.pdf";
    default:
      return "Modèle Courrier FTC.pdf";
  }
};

export const checkNumber = (value) => {
  let x = null;
  if (!value.includes(".")) x = Number(value);
  if (
    value.includes(".") &&
    value.split(".")[1] &&
    value.split(".")[1] !== ""
  ) {
    x = Number(value);
  }
  return typeof x === "number" && !isNaN(x);
};

export const updateFilters = (
  filters,
  operator,
  filterName,
  operation,
  value
) => {
  const newFilters = { ...filters };

  // Si la valeur est non nulle et non vide, ou si c'est un tableau (même vide)
  if ((value !== null && value !== "") || Array.isArray(value)) {
    // Si c'est un tableau vide, procéder à la suppression du filtre
    if (Array.isArray(value) && value.length === 0) {
      if (newFilters[operator]) {
        const index = newFilters[operator].findIndex(
          (filter) => Object.keys(filter)[0] === filterName
        );
        if (index !== -1) {
          newFilters[operator].splice(index, 1);
          if (newFilters[operator].length === 0) {
            delete newFilters[operator];
          }
        }
      }
    } else {
      // Sinon, ajouter ou mettre à jour le filtre normalement
      const filterOperation = { [operation]: value };
      if (newFilters[operator]) {
        const existingFilterIndex = newFilters[operator].findIndex(
          (filter) => Object.keys(filter)[0] === filterName
        );
        if (existingFilterIndex !== -1) {
          newFilters[operator][existingFilterIndex] = {
            [filterName]: filterOperation,
          };
        } else {
          newFilters[operator].push({ [filterName]: filterOperation });
        }
      } else {
        newFilters[operator] = [{ [filterName]: filterOperation }];
      }
    }
  } else {
    // Logique pour supprimer le filtre si besoin
    if (newFilters[operator]) {
      const index = newFilters[operator].findIndex(
        (filter) => Object.keys(filter)[0] === filterName
      );
      if (index !== -1) {
        newFilters[operator].splice(index, 1);
        if (newFilters[operator].length === 0) {
          delete newFilters[operator];
        }
      }
    }
  }

  return newFilters;
};

export const filtersToQueryString = ({ filters, options = {} }) => {
  if (Object.keys(filters).length === 0) {
    return ""; // Si aucun filtre n'est fourni, retourner une chaîne vide
  }

  let uniqueFilters = {};

  // Fonction auxiliaire pour traiter les valeurs (y compris de manière récursive pour les objets)
  const processValue = (value) => {
    if (value instanceof Date) {
      // Formatage de la date au format ISO avec Z pour indiquer le temps UTC
      return `{"$date":"${value.toISOString()}"}`;
    } else if (Array.isArray(value)) {
      // Gestion des tableaux
      return `[${value.map((v) => processValue(v)).join(",")}]`;
    } else if (typeof value === "object" && value !== null) {
      // Gestion récursive des objets
      let result = {};
      for (const [key, val] of Object.entries(value)) {
        result[key] = processValue(val); // Appel récursif pour chaque propriété de l'objet
      }
      return `{${Object.entries(result)
        .map(([k, v]) => `"${k}":${v}`)
        .join(",")}}`;
    } else {
      // Gestion des autres types de valeurs
      return typeof value === "string" ? `"${value}"` : value;
    }
  };

  Object.entries(filters).forEach(([groupOperator, conditions]) => {
    conditions.forEach((condition) => {
      const [field, conditionObj] = Object.entries(condition)[0];
      const [operator, value] = Object.entries(conditionObj)[0];

      // Définir une clé unique basée sur le champ et l'opérateur
      const uniqueKey = `${field}_${operator}`;

      // Vérifier si la valeur est invalide
      let isInvalidValue =
        value === null ||
        value === "" ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0);

      if (!isInvalidValue) {
        // Ajouter ou mettre à jour le filtre avec la nouvelle valeur valide
        uniqueFilters[uniqueKey] = {
          groupOperator,
          field,
          operator,
          value: processValue(value), // Utilisation de la fonction auxiliaire pour traiter la valeur
        };
      }
    });
  });

  // Suppression des filtres si demandé dans les options
  if (options.removeFilters) {
    options.removeFilters.forEach((removeKey) => {
      Object.keys(uniqueFilters).forEach((key) => {
        if (key.includes(removeKey)) {
          delete uniqueFilters[key];
        }
      });
    });
  }

  // Construction de la chaîne de requête finale
  return Object.values(uniqueFilters)
    .map(({ groupOperator, field, operator, value }) => {
      return `${groupOperator}={"${field}":{"${operator}":${value}}}`;
    })
    .join("&");
};

export const removeFilters = (
  filters,
  groupOperator,
  fieldToRemove,
  operationToRemove
) => {
  // Vérifier si le groupe d'opérateurs spécifié est présent dans filters
  if (filters[groupOperator]) {
    // Filtrer les conditions dans le groupe d'opérateurs spécifié
    let filteredConditions = filters[groupOperator].filter((condition) => {
      // Vérifier si la condition concerne le champ à supprimer
      if (condition.hasOwnProperty(fieldToRemove)) {
        // Vérifier si l'opération spécifiée est celle à supprimer
        return !condition[fieldToRemove].hasOwnProperty(operationToRemove);
      }
      return true;
    });

    // Si après filtrage, il reste des conditions, on met à jour filters
    if (filteredConditions.length > 0) {
      filters[groupOperator] = filteredConditions;
    } else {
      // Si aucune condition ne reste dans le groupe, on supprime le groupe entier
      delete filters[groupOperator];
    }
  }
  // Retourner l'objet filters mis à jour
  return filters;
};

export const getOrdoCodes = [
  "AT",
  "BF MED",
  "BF MEDG4",
  "CANL",
  "CADXG6",
  "CADXG6S",
  "ST",
  "STD",
  "STMT",
  "SP BF",
  "SP ST",
  "SP STD",
  "SP AT",
  "BF CAMAPS",
  "BF TANDEM",
  "AT MED",
  "AT YPSO",
  "AT TANDEM",
  "AT BIQ",
  "AT MED740G",
  "BF DIABELOOP",
  "STMT300",
  "BF O5",
];

export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;
export const passwordErrorMessage =
  "Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 nombre, 1 symbole.";

export const Locale = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "OK",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heure",
  minutes: "Minutes",
  seconds: "Secondes",
};

export const getInterventionPicto = (type) => {
  switch (type) {
    case "Courrier":
      return "download-courrier.svg";
    case "Évaluation":
      return "download-evaluation.svg";
    case "Téléchargement Dispositif":
      return "download-telechargement-dispositif.svg";
    default:
      return "download.svg";
  }
};
