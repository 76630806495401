import moment from "moment";

export const checkSignable = ({
  code,
  finess,
  finessOther,
  socialReason,
  startDate,
  period,
  catheter,
}) => {
  let isSignable = false;
  let isFinessValid = false;
  let isPeriodValid = false;

  if (
    [
      "ST",
      "STD",
      "STMT",
      "SP ST",
      "SP STD",
      "SP AT",
      "CANL",
      "CADXG6",
      "CADXG6S",
    ].includes(code)
  ) {
    isSignable = true;
  } else if (
    [
      "AT",
      "BF MED",
      "BF MEDG4",
      "SP BF",
      "BF CAMAPS",
      "BF TANDEM",
      "BF O5",
      "AT MED",
      "AT YPSO",
      "AT TANDEM",
      "AT BIQ",
      "AT MED740G",
      "BF DIABELOOP",
      "STMT300",
    ].includes(code) /*&&
    typeof catheter === "string" &&
    catheter.trim() !== ""*/
  ) {
    isSignable = true;
  }
  if (typeof finess === "string" && finess.trim() !== "") {
    isFinessValid = true;
  } else if (
    typeof finessOther === "string" &&
    finessOther.trim() !== "" &&
    typeof socialReason === "string" &&
    socialReason.trim() !== ""
  ) {
    isFinessValid = true;
  }

  isPeriodValid =
    typeof period === "string" &&
    period.trim() !== "" &&
    availablPeriodItems(code)
      .map((item) => item.value)
      .includes(period);

  return (
    isSignable &&
    isFinessValid &&
    startDate &&
    moment(startDate).isValid() &&
    isPeriodValid
  );
};

export const getLabelPrescription = (plan) => {
  switch (plan) {
    case "ST":
      return "SANS TUBULURE";
    case "STD":
      return "SANS TUBULURE DASH";
    case "STMT":
      return "ST MEDTRUM TOUCHCARE";
    case "AT":
      return "AVEC TUBULURE";
    case "BF MED":
      return "BF MEDTRONIC";
    case "BF MEDG4":
      return "BF MEDTRONIC G4";
    case "CANL":
      return "CAPTEUR EN LITE";
    case "CADXG6":
      return "CAPTEUR DEXCOM G6";
    case "CADXG6S":
      return "CAPTEUR DEXCOM G6";
    case "SP BF":
      return "SUSPENSION MEDTRONIC 780G";
    case "SP ST":
      return "SUSPENSION OMNIPOD";
    case "SP STD":
      return "SUSPENSION OMNIPOD DASH";
    case "SP AT":
      return "SUSPENSION POMPE A INSULINE";
    case "BF CAMAPS":
      return "BF YPSOMED CAMAPS";
    case "BF TANDEM":
      return "BF TANDEM";
    case "AT MED":
      return "AT MEDTRONIC";
    case "AT YPSO":
      return "AT YPSOMED";
    case "AT TANDEM":
      return "AT TANDEM";
    case "AT BIQ":
      return "AT TANDEM BIQ";
    case "AT MED740G":
      return "AT MEDTRONIC 740G";
    case "BF DIABELOOP":
      return "BF DIABELOOP";
    case "STMT300":
      return "ST MEDTRUM TOUCHCARE 300";
    case "BF O5":
      return "BF OMNIPOD 5";
    default:
      return null;
  }
};

export const contractSettings = (plan) => {
  let settings = {
    label: getLabelPrescription(plan),
    items: {
      careSet: { display: false, default: 1 },
      tegaderm6X7: { display: false, default: 1 },
      tegaderm10X15: { display: false, default: 1 },
      catheter: { display: false, default: "" },
      autocontrol: { display: false, default: false },
    },
  };
  settings.enabled = settings.label !== null;
  switch (plan) {
    case "ST":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "STD":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "STMT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "AT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF MED":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF MEDG4":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "CANL":
      settings.items.autocontrol.display = true;
      break;
    case "CADXG6":
      settings.items.autocontrol.display = true;
      break;
    case "CADXG6S":
      settings.items.autocontrol.display = true;
      break;
    case "SP BF":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "SP ST":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "SP STD":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "SP AT":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      break;
    case "BF CAMAPS":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF TANDEM":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF O5":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT MED":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT YPSO":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT TANDEM":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT BIQ":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "AT MED740G":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    case "BF DIABELOOP":
      //settings.items.catheter.display = true;
      break;
    case "STMT300":
      settings.items.careSet.display = true;
      settings.items.tegaderm6X7.display = true;
      settings.items.tegaderm10X15.display = true;
      //settings.items.catheter.display = true;
      break;
    default:
      return settings;
  }
  return settings;
};

export const isPrescriptionDateLock = (plan) => {
  return [
    "AT",
    "AT MED",
    "AT YPSO",
    "AT TANDEM",
    "AT BIQ",
    "AT MED740G",
    "ST",
    "STD",
    "STMT",
    "STMT300",
  ].includes(plan);
};

export const availablPeriodItems = (plan) => {
  let periodItems = [
    { label: "6 mois renouvelable 1 fois", value: "6MR1" },
    { label: "3 mois", value: "3M" },
    { label: "6 mois", value: "6M" },
  ];

  if (
    [
      "BF MED",
      "BF MEDG4",
      "BF CAMAPS",
      "BF TANDEM",
      "BF DIABELOOP",
      "BF O5",
    ].includes(plan)
  ) {
    periodItems = periodItems.filter((item) => item.value !== "3M");
  }

  return periodItems;
};
